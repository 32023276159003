import React from 'react';
import tw from 'twin.macro';
import { graphql } from 'gatsby';
import CategoryPageLayout from '../../components/CategoryPageLayout';
import { Inner, headerInnerStyles } from '../../components';
import FilterProvider from '../../utils/hooks/use-filter';

const TITLE = 'Tech Talents and Doing Digital Differently';
const DESCRIPTION =
  'Here you can find all our blog articles, founder interviews & tech talents portraits.';
const SEO_DESCRIPTION =
  'Do you live in one of the happiest country in the world? Discover many intriguing stories of internationals who came to live and work in Denmark.';

const TechTalentsPage = ({ data }) => {
  const techTalents = data.allDatoCmsTechTalent.edges.map((techTalent) => techTalent.node);
  return (
    <CategoryPageLayout
      title={TITLE}
      description={DESCRIPTION}
      seoDescription={SEO_DESCRIPTION}
      posts={techTalents}
      slug="tech-talents"
    />
  );
};

export default TechTalentsPage;

export const techTalentsQuery = graphql`
  {
    allDatoCmsTechTalent(sort: { fields: meta___firstPublishedAt, order: DESC }) {
      edges {
        node {
          title
          slug
          ribbonCaption
          expert {
            techTalentImage {
              gatsbyImageData(
                placeholder: BLURRED
                imgixParams: { crop: "focalpoint", fit: "crop", w: "615", h: "450" }
              )
            }
          }
          contentNode {
            childMarkdownRemark {
              excerpt
            }
          }
          model {
            name
          }
          date(formatString: "DD.MM.YYYY")
          dateTime: date(formatString: "YYYY.MM.DD")
          sortDate: date(formatString: "YYYYMMDD")
          ecospheres {
            label
          }
        }
      }
    }
  }
`;
